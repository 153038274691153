<template>
  <div>
    <el-tabs tab-position="top" :stretch="true"  type="border-card">
      <el-tab-pane>
        <span slot="label">业务知识</span>
        <el-card class="box-card" style="margin: 20px 30px" shadow="never">
          <div slot="header" class="clearfix">
            <span>操作员</span>
            <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
          </div>
          <el-form ref="form" :model="operatorFrom" label-width="150px" style="width:80%" >
              <el-form-item label="温度:" >
                <div class="line">
                  <el-slider v-model="operatorFrom.temperature" class="slider" :format-tooltip="formatTooltip1" :max="20" ></el-slider>
                  <span class="text" >{{formatTooltip1(operatorFrom.temperature)}}</span>
                </div>   
              </el-form-item>
              <el-form-item label="历史对话轮数:">
                <el-input v-model.number="operatorFrom.historyCount" type="number"  min="0" max="10"></el-input>
              </el-form-item>
              <el-form-item label="匹配知识条数:">
                <el-input v-model.number="operatorFrom.topK" type="number"  min="1" max="10"></el-input>
              </el-form-item>
              <el-form-item label="知识匹配分数数阀值:">
                <div class="line">
                  <el-slider v-model="operatorFrom.scoreThreshold" class="slider" :format-tooltip="formatTooltip"  ></el-slider>
                  <span class="text" >{{formatTooltip(operatorFrom.scoreThreshold)}}</span>
                </div>            
              </el-form-item>
              <el-form-item style="display: flex;justify-content: center;align-items: center;">
                <el-button @click.native="resettingFunction(operatorFrom.configType,operatorFrom.contentType)" :disabled="fireTrainingLoading" >重置</el-button>
                <el-button type="primary" @click.native="saveOperatorModelConfig(operatorFrom.contentType)" :disabled="fireTrainingLoading" >应用</el-button>            
              </el-form-item>
          </el-form>    
        </el-card>
        <el-card class="box-card" style="margin: 40px 30px" shadow="never">
          <div slot="header" class="clearfix">
            <span>社会消防培训</span>
          </div>
            <el-form ref="form" :model="fireTrainingFrom" label-width="150px" style="width:80%" >
              <el-form-item label="温度:">
                <div class="line">
                  <el-slider v-model="fireTrainingFrom.temperature" class="slider" label="44" :format-tooltip="formatTooltip1" :max="20"></el-slider>
                  <span class="text" >{{formatTooltip1(fireTrainingFrom.temperature)}}</span>
                </div>            
              </el-form-item>
              <el-form-item label="历史对话轮数:">
                <el-input v-model.number="fireTrainingFrom.historyCount" type="number"  min="0" max="1" label="fdfd"></el-input>
              </el-form-item>
              <el-form-item label="匹配知识条数:">
                <el-input v-model.number="fireTrainingFrom.topK" type="number"  min="1" max="10"></el-input>
              </el-form-item>
              <el-form-item label="知识匹配分数数阀值:">
                <div class="line">
                  <el-slider v-model="fireTrainingFrom.scoreThreshold" class="slider" :format-tooltip="formatTooltip"  ></el-slider>
                  <span class="text" >{{formatTooltip(fireTrainingFrom.scoreThreshold)}}</span>
                </div>  
              </el-form-item>
              <el-form-item style="display: flex;justify-content: center;align-items: center;">
                <el-button @click.native="resettingFunction(fireTrainingFrom.configType,fireTrainingFrom.contentType)" :disabled="fireTrainingLoading" >重置</el-button>
                <el-button type="primary" @click.native="saveFireTrainingModelConfig(fireTrainingFrom.contentType)" :loading="fireTrainingLoading">应用</el-button>            
              </el-form-item>
          </el-form>
          
        </el-card>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label">专业知识</span>
        <el-card class="box-card" style="margin: 20px 30px" shadow="never">
          <div slot="header" class="clearfix">
            <span>操作员</span>
          </div>
          <el-form ref="form" :model="operatorSpecialityFrom" label-width="150px" style="width:80%" >
              <el-form-item label="温度:" >
                <div class="line">
                  <el-slider v-model="operatorSpecialityFrom.temperature" class="slider" :format-tooltip="formatTooltip1" :max="20" ></el-slider>
                  <span class="text" >{{formatTooltip1(operatorSpecialityFrom.temperature)}}</span>
                </div>   
              </el-form-item>
              <el-form-item label="历史对话轮数:">
                <el-input v-model.number="operatorSpecialityFrom.historyCount" type="number"  min="0" max="10"></el-input>
              </el-form-item>
              <el-form-item label="匹配知识条数:">
                <el-input v-model.number="operatorSpecialityFrom.topK" type="number"  min="1" max="10"></el-input>
              </el-form-item>
              <el-form-item label="知识匹配分数数阀值:">
                <div class="line">
                  <el-slider v-model="operatorSpecialityFrom.scoreThreshold" class="slider" :format-tooltip="formatTooltip"  ></el-slider>
                  <span class="text" >{{formatTooltip(operatorSpecialityFrom.scoreThreshold)}}</span>
                </div>            
              </el-form-item>
              <el-form-item style="display: flex;justify-content: center;align-items: center;">
                <el-button @click.native="resettingFunction(operatorSpecialityFrom.configType,operatorSpecialityFrom.contentType)" :disabled="fireTrainingLoading" >重置</el-button>
                <el-button type="primary" @click.native="saveOperatorModelConfig(operatorSpecialityFrom.contentType)" :disabled="fireTrainingLoading">应用</el-button>            
              </el-form-item>
          </el-form>    
        </el-card>
        <el-card class="box-card" style="margin: 40px 30px" shadow="never">
          <div slot="header" class="clearfix">
            <span>社会消防培训</span>
            <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
          </div>
            <el-form ref="form" :model="fireTrainingSpecialityFrom" label-width="150px" style="width:80%" >
              <el-form-item label="温度:">
                <div class="line">
                  <el-slider v-model="fireTrainingSpecialityFrom.temperature" class="slider" label="44" :format-tooltip="formatTooltip1" :max="20"></el-slider>
                  <span class="text" >{{formatTooltip1(fireTrainingSpecialityFrom.temperature)}}</span>
                </div>            
              </el-form-item>
              <el-form-item label="历史对话轮数:">
                <el-input v-model.number="fireTrainingSpecialityFrom.historyCount" type="number"  min="0" max="1" label="fdfd"></el-input>
              </el-form-item>
              <el-form-item label="匹配知识条数:">
                <el-input v-model.number="fireTrainingSpecialityFrom.topK" type="number"  min="1" max="10"></el-input>
              </el-form-item>
              <el-form-item label="知识匹配分数数阀值:">
                <div class="line">
                  <el-slider v-model="fireTrainingSpecialityFrom.scoreThreshold" class="slider" :format-tooltip="formatTooltip"  ></el-slider>
                  <span class="text" >{{formatTooltip(fireTrainingSpecialityFrom.scoreThreshold)}}</span>
                </div>  
              </el-form-item>
              <el-form-item style="display: flex;justify-content: center;align-items: center;">
                <el-button @click.native="resettingFunction(fireTrainingSpecialityFrom.configType,fireTrainingSpecialityFrom.contentType)" :disabled="fireTrainingLoading" >重置</el-button>
                <el-button type="primary" @click.native="saveFireTrainingModelConfig(fireTrainingSpecialityFrom.contentType)"  :disabled="fireTrainingLoading" >应用</el-button>            
              </el-form-item>
          </el-form>
         
        </el-card>
      </el-tab-pane>
    </el-tabs>
   

  </div>
</template>
<script >
import util from "../../../util/date";
import { validPhone } from "../../../util/validate";
import {
	getModelConfigInfo,
  saveModelConfig
} from "../../api/api";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from "qs";
export default {
  components: { Toolbar },
    data() {
      return {
        operatorLoading:false,
        fireTrainingLoading:false,
        operatorFrom:{
          configId:null,
          configType:1,
          contentType:1,
          historyCount:0,//历史对话轮数
          scoreThreshold:0,//知识匹配分数阈值
          temperature:0,//温度
          topK:0//匹配知识条数
        },
        fireTrainingFrom:{
          configId:null,
          configType:2,
          contentType:1,
          historyCount:0,
          scoreThreshold:0,
          temperature:0,
          topK:0
        },        
        operatorSpecialityFrom:{
          configId:null,
          configType:1,
          contentType:2,
          historyCount:0,//历史对话轮数
          scoreThreshold:0,//知识匹配分数阈值
          temperature:0,//温度
          topK:0//匹配知识条数
        },
        fireTrainingSpecialityFrom:{
          configId:null,
          configType:2,
          contentType:2,
          historyCount:0,
          scoreThreshold:0,
          temperature:0,
          topK:0
        },
      }
    },
    methods: {
      resettingFunction(configType,contentType){
        this.fireTrainingLoading = false;
        let params = {};
        if(configType==1 && contentType==1){
          this.operatorFrom.historyCount=0;
          this.operatorFrom.scoreThreshold=0;
          this.operatorFrom.temperature=0;
          this.operatorFrom.topK=0;
          params = Object.assign({}, this.operatorFrom); 
        }else if(configType==2 && contentType==1){
          this.fireTrainingFrom.historyCount=0;
          this.fireTrainingFrom.scoreThreshold=0;
          this.fireTrainingFrom.temperature=0;
          this.fireTrainingFrom.topK=0;
          params = Object.assign({}, this.fireTrainingFrom); 
        }else if(configType==1 && contentType==2){
          this.operatorSpecialityFrom.historyCount=0;
          this.operatorSpecialityFrom.scoreThreshold=0;
          this.operatorSpecialityFrom.temperature=0;
          this.operatorSpecialityFrom.topK=0;
          params = Object.assign({}, this.operatorSpecialityFrom); 
        }else if(configType==2 && contentType==2){
          this.fireTrainingSpecialityFrom.historyCount=0;
          this.fireTrainingSpecialityFrom.scoreThreshold=0;
          this.fireTrainingSpecialityFrom.temperature=0;
          this.fireTrainingSpecialityFrom.topK=0;
          params = Object.assign({}, this.fireTrainingSpecialityFrom); 
        }
        saveModelConfig(params).then((res)=>{
            if (util.isEmt.format(res)) {
								this.fireTrainingLoading = false;
								return;
							}
							if (res.data.success) {
								this.fireTrainingLoading = false;
								this.$message({
									message: res.data.message,
									type: 'success'
								});
								// this.getDataPermissions();
							}
							else {
								this.fireTrainingLoading = false;
								this.$message({
									message: res.data.message,
									type: 'error'
								});
							}
          });
      },
      saveOperatorModelConfig(contentType){
        this.$confirm('确认应用吗？', '提示', {}).then(() => {
          this.fireTrainingLoading = true;
          let params = {};
          if(contentType==1){
            var temperatureDecimal=this.formatTooltip1(this.operatorFrom.temperature);
            var scoreThresholdDecimal=this.formatTooltip(this.operatorFrom.scoreThreshold);
            params = Object.assign({}, this.operatorFrom); 
            params.temperature=temperatureDecimal;
            params.scoreThreshold=scoreThresholdDecimal;
          }else {
            var temperatureDecimal=this.formatTooltip1(this.operatorSpecialityFrom.temperature);
            var scoreThresholdDecimal=this.formatTooltip(this.operatorSpecialityFrom.scoreThreshold);
            params = Object.assign({}, this.operatorSpecialityFrom); 
            params.temperature=temperatureDecimal;
            params.scoreThreshold=scoreThresholdDecimal;
          }
          saveModelConfig(params).then((res)=>{
            if (util.isEmt.format(res)) {
								this.fireTrainingLoading = false;
								return;
							}
							if (res.data.success) {
								this.fireTrainingLoading = false;
								this.$message({
									message: res.data.message,
									type: 'success'
								});
								// this.getDataPermissions();
							}
							else {
								this.operatorLoading = false;
								this.$message({
									message: res.data.message,
									type: 'error'
								});
							}
          });
        });
      },
      saveFireTrainingModelConfig(contentType){
        this.$confirm('确认应用吗？', '提示', {}).then(() => {
          this.fireTrainingLoading = true;
          let params = {};
          if(contentType==1){
            var temperatureDecimal=this.formatTooltip1(this.fireTrainingFrom.temperature);
            var scoreThresholdDecimal=this.formatTooltip(this.fireTrainingFrom.scoreThreshold);
            params = Object.assign({}, this.fireTrainingFrom); 
            params.temperature=temperatureDecimal;
            params.scoreThreshold=scoreThresholdDecimal;
          }else {
            var temperatureDecimal=this.formatTooltip1(this.fireTrainingSpecialityFrom.temperature);
            var scoreThresholdDecimal=this.formatTooltip(this.fireTrainingSpecialityFrom.scoreThreshold);
            params = Object.assign({}, this.fireTrainingSpecialityFrom); 
            params.temperature=temperatureDecimal;
            params.scoreThreshold=scoreThresholdDecimal;
          }
          
          saveModelConfig(params).then((res)=>{
            if (util.isEmt.format(res)) {
								this.fireTrainingLoading = false;
								return;
							}
							if (res.data.success) {
								this.fireTrainingLoading = false;
								this.$message({
									message: res.data.message,
									type: 'success'
								});
								// this.getDataPermissions();
							}
							else {
								this.fireTrainingLoading = false;
								this.$message({
									message: res.data.message,
									type: 'error'
								});
							}
          });
        });
      },

      getModelConfigInfoFunction(){
        getModelConfigInfo().then((res)=>{
            if (res.data.success) {
                var modelConfigList = res.data.response  ;
                var operatorOne= modelConfigList.find(function(e){
                  return e.configType==1 && e.contentType==1;
                });
                if(operatorOne!=null){
                  this.operatorFrom = operatorOne;
                  this.operatorFrom.temperature = operatorOne.temperature*10;
                  this.operatorFrom.scoreThreshold=operatorOne.scoreThreshold*100;
                }
                var fireTrainingOne= modelConfigList.find(function(e){
                  return e.configType==2 && e.contentType==1;
                });
                if(fireTrainingOne!=null){
                  this.fireTrainingFrom = fireTrainingOne;
                  this.fireTrainingFrom.temperature = fireTrainingOne.temperature*10;
                  this.fireTrainingFrom.scoreThreshold=fireTrainingOne.scoreThreshold*100;
                }
                //专业知识
                var operatorSpecialityOne= modelConfigList.find(function(e){
                  return e.configType==1 && e.contentType==2;
                });
                if(operatorSpecialityOne!=null){
                  this.operatorSpecialityFrom = operatorSpecialityOne;
                  this.operatorSpecialityFrom.temperature = operatorSpecialityOne.temperature*10;
                  this.operatorSpecialityFrom.scoreThreshold=operatorSpecialityOne.scoreThreshold*100;
                }
                var fireTrainingSpecialityOne= modelConfigList.find(function(e){
                  return e.configType==2 && e.contentType==2;
                });
                if(fireTrainingSpecialityOne!=null){
                  this.fireTrainingSpecialityFrom = fireTrainingSpecialityOne;
                  this.fireTrainingSpecialityFrom.temperature = fireTrainingSpecialityOne.temperature*10;
                  this.fireTrainingSpecialityFrom.scoreThreshold=fireTrainingSpecialityOne.scoreThreshold*100;
                }
            }
        });
      },
      formatTooltip1(val) {
        return val / 10;
      },
      formatTooltip(val) {
        return val / 100;
      }
    },
    mounted() {
      this.getModelConfigInfoFunction();
    }
};
</script>
<style lang="stylus" scoped>

.slider {
  margin-left: 10px;
  width:calc(100% - 50px)
}

.line{
  display: flex;
  align-items:center
}

.text{
  margin-left:10px;
  width:20px;
}

::v-deep .el-tabs--border-card{
  border: 1px solid rgb(64, 158, 255);
}

::v-deep .el-tabs--border-card>.el-tabs__header{
  background-color: #FFF;
}
::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item{
  border: 1px solid #409eff;
}


::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
  background-color:rgb(64, 158, 255)
  color: #FFF;
}


</style>
